(function ($) {

  // ##########
  // global functions
  // ##########

  // sticky nav header variable
  var sticky_main_header = $('.main_header');

  // sticky nav scroll event
  function sticky_nav_toggle(scroll_distance) {
    if (scroll_distance >= 50) {
      sticky_main_header.addClass('sticky_nav');
    } else {
      sticky_main_header.removeClass('sticky_nav');
    }
  }

  // ##########
  // page load scripts
  // ##########

  $(window).load(function () {
    var scroll_distance = $(window).scrollTop();
    sticky_nav_toggle(scroll_distance);
  });

  var Sage = {

    // ##########
    // common pages
    // ##########

    'common': {

      init: function () {

        // ##########
        // initialize common scripts

        new WOW({ offset: 100 }).init();

        // ##########
        // initialize common variables

        var main_header = $('.main_header'),
          mobile_nav_open = $('.mobile_nav_open'),
          mobile_nav_close = $('.mobile_nav_close'),
          main_header__nav = $('.main_header__nav'),
          main_nav_child_nav = $('.menu-item-has-children > a');

        // ##########
        // main nav toggles

        // open nav
        mobile_nav_open.on('click', function (e) {
          e.preventDefault();
          main_header__nav.slideDown();
        });

        // close nav
        mobile_nav_close.on('click', function (e) {
          e.preventDefault();
          main_header__nav.slideUp();
          main_nav_child_nav.next('ul').slideUp();
        });

        // toggle subnav
        main_nav_child_nav.on('click', function (e) {

          // only execute when nav is toggled
          if (mobile_nav_close.is(':visible')) {

            var child_nav = $(this).next('ul');
            e.preventDefault();

            if (child_nav.is(':visible')) {
              var this_href = $(this).attr('href');

              if (this_href === '#') {
                child_nav.slideUp();
              } else {
                window.location = this_href;
              }
            } else {
              child_nav.slideDown();
            }
          } else {

            if ($(this).attr('href') === '#') {
              e.preventDefault();
            }
          }
        });

        // ##########
        // smooth scroll - on click

        $('a[href*="#"]')
        // Remove links that don't actually link to anything
          .not('[href="#"]')
          .not('[href="#0"]')
          .click(function (event) {
            // On-page links
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
              // Figure out element to scroll to
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

              // Does a scroll target exist?
              if (target.length) {

                // Only prevent default if animation is actually gonna happen
                event.preventDefault();

                $('html, body').animate({
                  scrollTop: target[0].offsetTop - 50
                }, 2000, function () {
                  // Callback after animation
                  // Must change focus for accessibility
                  var $target = $(target);
                  $target.focus();
                  if ($target.is(":focus")) { // Checking if the target was focused
                    return false;
                  } else {
                    $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                    $target.focus(); // Set focus again
                  }
                });
              }
            }
          });

        // ##########
        // smooth scroll - on load

        if ($(location.hash).length === 1) {
          var target = $(location.hash);
          target = target.length ? target : $('[name=' + location.hash.slice(1) + ']');

          if (target.length) {

            $('html, body').animate({
              scrollTop: target[0].offsetTop - 50
            }, 2000, function () {

              // prevent screen jumping
              $('html, body').animate({
                scrollTop: target[0].offsetTop - 50
              }, 0);
            });
          }
        }

        // ##########
        // partners slider

        var about_partners__slider__slides = $('.about_partners__slider__slides');

        if (about_partners__slider__slides.length) {

          about_partners__slider__slides.slick({
            centerMode: true,
            slidesToShow: 4,
            arrows: true,
            prevArrow: '#partners_prev',
            nextArrow: '#partners_next',
            autoplay: true,
            responsive: [
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 3,
                  centerMode: false
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  centerMode: false
                }
              }
            ]
          });
        }

        // ##########
        // news slider

        var front_news__slider = $('.front_news__slider');

        if (front_news__slider.length) {

          front_news__slider.slick({
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: '<a href="#" class="front_news__slider__arrows prev_arrow"><span class="fas fa-chevron-left"></span></a>',
            nextArrow: '<a href="#" class="front_news__slider__arrows next_arrow"><span class="fas fa-chevron-right"></span></a>',
            autoplay: true,
            autoplaySpeed: 5000,
            responsive: [
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 2
                }
              },
              {
                breakpoint: 576,
                settings: {
                  slidesToShow: 1
                }
              }
            ]
          });
        }

        // ##########
        // common scroll event

        $(window).scroll(function (e) {
          var scroll_distance = $(window).scrollTop();
          sticky_nav_toggle(scroll_distance);
        });
      },

      finalize: function () {

      }
    },

    // ##########
    // front page template
    // ##########

    'page_template_template_front': {

      init: function () {

        // ##########
        // hero slider

        $('.front_hero__bg__slider').slick({
          arrows: false,
          autoplay: true,
          autoplaySpeed: 8000,
          pauseOnHover: false,
          pauseOnFocus: false
        });

        // ##########
        // draw svgs

        new Vivus('shovel_svg', {
          duration: 120,
          onReady: function () {
            $('.hero_callouts__callout__heading__icon svg').css('visibility', 'visible');
          }
        });
        new Vivus('circle_heart_svg', { duration: 120 });
        new Vivus('fork_diamond_svg', { duration: 120 });

        // ##########
        // stats bars

        // create scroll controller
        var controller = new ScrollMagic.Controller();

        // first bar
        var front_stats__stat_circle__color_bar__first = new ProgressBar.Circle('#front_stats__stat_circle__color_bar__first', {
          strokeWidth: 13,
          easing: 'easeInOut',
          duration: 1400,
          color: '#f68d2d',
          trailColor: '#bbb',
          trailWidth: 13,
          svgStyle: null
        });

        // create first bar scene
        var front_stats__stat_circle__color_bar__first__scene = new ScrollMagic.Scene({
          triggerElement: '#front_stats__stat_circle__color_bar__first',
          offset: 200,
          triggerHook: "onEnter",
          reverse: false
        }).addTo(controller);

        // animate first bar
        front_stats__stat_circle__color_bar__first__scene.on('enter', function () {
          front_stats__stat_circle__color_bar__first.animate(1);
        });

        // second bar
        var front_stats__stat_circle__color_bar__second = new ProgressBar.Circle('#front_stats__stat_circle__color_bar__second', {
          strokeWidth: 13,
          easing: 'easeInOut',
          duration: 1400,
          color: '#01a1dd',
          trailColor: '#bbb',
          trailWidth: 13,
          svgStyle: null
        });

        // create second bar scene
        var front_stats__stat_circle__color_bar__second__scene = new ScrollMagic.Scene({
          triggerElement: '#front_stats__stat_circle__color_bar__second',
          offset: 200,
          triggerHook: "onEnter",
          reverse: false
        }).addTo(controller);

        // animate second bar
        front_stats__stat_circle__color_bar__second__scene.on('enter', function () {
          front_stats__stat_circle__color_bar__second.animate(1);
        });

        // third bar
        var front_stats__stat_circle__color_bar__third = new ProgressBar.Circle('#front_stats__stat_circle__color_bar__third', {
          strokeWidth: 13,
          easing: 'easeInOut',
          duration: 1400,
          color: '#86c440',
          trailColor: '#bbb',
          trailWidth: 13,
          svgStyle: null
        });

        // create third bar scene
        var front_stats__stat_circle__color_bar__third__scene = new ScrollMagic.Scene({
          triggerElement: '#front_stats__stat_circle__color_bar__third',
          offset: 200,
          triggerHook: "onEnter",
          reverse: false
        }).addTo(controller);

        // animate third bar
        front_stats__stat_circle__color_bar__third__scene.on('enter', function () {
          front_stats__stat_circle__color_bar__third.animate(1);
        });

        // fourth bar
        var front_stats__stat_circle__color_bar__fourth = new ProgressBar.Circle('#front_stats__stat_circle__color_bar__fourth', {
          strokeWidth: 13,
          easing: 'easeInOut',
          duration: 1400,
          color: '#f68d2d',
          trailColor: '#bbb',
          trailWidth: 13,
          svgStyle: null
        });

        // create fourth bar scene
        var front_stats__stat_circle__color_bar__fourth__scene = new ScrollMagic.Scene({
          triggerElement: '#front_stats__stat_circle__color_bar__fourth',
          offset: 200,
          triggerHook: "onEnter",
          reverse: false
        }).addTo(controller);

        // animate fourth bar
        front_stats__stat_circle__color_bar__fourth__scene.on('enter', function () {
          front_stats__stat_circle__color_bar__fourth.animate(1);
        });

      },

      finalize: function () {

      }
    },

    // ##########
    // front page template
    // ##########

    'page_template_template_about': {

      init: function () {

      }
    }
  };

  // ##########
  // fire off all scripts
  // ##########

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
